import { PageProps } from 'gatsby';
import { FC } from 'react';
import tw from 'twin.macro';
import {
  AccessCard,
  BlueButton,
  breakoutRightDesktop,
  CallOut,
  Contact,
  ContentSection,
  Div,
  DownloadButton,
  Footnotes,
  H2,
  H3,
  Hero,
  HR,
  Layout,
  LI,
  LinkPlus,
  links,
  NERLYNX,
  P,
  SEO,
  UL,
  VoucherFooter,
} from '~/components';
import { PhoneLink } from '~/components/common/phone-link';
import { Frontmatter } from '~/config';
import Copay from '~/images/copay.svg';
import File from '~/images/file.svg';
import heroBackground from '~/images/hero-laptop.jpg';
import MagnifyingGlass from '~/images/magnifying-glass.svg';
import Mentor from '~/images/mentor.svg';
import Money from '~/images/money.svg';
import MouseClick from '~/images/mouse-click.svg';
import Nurse from '~/images/nurse.svg';
import PatientPumalynx from '~/images/patient-pumalynx.svg';
import Quote from '~/images/quote.svg';
// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Access and Support',
  desktopNavTitle: 'Access and\\nSupport',
  order: 6,
  screenShoot: true,
  anchors: [
    {
      id: 'getting-patients-started',
      label: 'GETTING PATIENTS STARTED',
      ariaLabel: 'Getting patients started',
    },
    {
      id: 'reimbursement-and-ongoing-treatment',
      label: 'COVERAGE AND ONGOING TREATMENT',
      ariaLabel: 'Coverage and Ongoing Treatment',
    },
    {
      id: 'specialty-pharmacies-distributors',
      label: 'SPECIALTY PHARMACIES / DISTRIBUTORS',
      mobileLabel: 'SPECIALTY PHARMACIES/DISTRIBUTORS',
      sitemapLabel: 'SPECIALTY PHARMACIES/DISTRIBUTORS',
      ariaLabel: 'Specialty pharmacies/distributors',
    },
  ],
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout anchors={frontmatter.anchors}>
      <SEO
        title="Access and support | NERLYNX® (neratinib) tablets"
        description="Learn about the patient support program, access and reimbursement options, and the co-pay card for NERLYNX treatment."
        ogDescription="Learn about the patient support options for NERLYNX treatment."
        path={props.location?.pathname}
      />
      <Hero
        backgroundImage={heroBackground}
        characterWidth={50}
        headlinePadding="right"
        backgroundStyle={tw`background-position[80%] bg-cover md:(bg-right)`}
        titleStyle={tw`mb-24 md:(mb-16)`}
      >
        Programs to help support your patients
      </Hero>
      <section id="getting-patients-started">
        <ContentSection customCss={[tw`pb-12`]}>
          <H2 cyan tw="mb-8">
            Use the following resources to get your eligible patients started on{' '}
            <NERLYNX />
          </H2>
          <Div
            customCss={[
              tw`grid grid-cols-1 gap-8 md:(grid-cols-2)`,
              breakoutRightDesktop,
            ]}
          >
            <CallOut
              blue
              bgColor="pastelGray"
              barColor="cyan"
              ctaText="Enrollment Form"
              customCss={tw`py-4 md:(py-8)`}
            >
              <Div customCss={tw`flex flex-col lg:(flex-row)`}>
                <DownloadButton
                  customCss={tw`mb-4 flex gap-4 justify-between lg:(mr-4 mb-0)`}
                  to="/pdf/enrollment-form.pdf"
                >
                  English
                </DownloadButton>
                <DownloadButton
                  customCss={tw`flex gap-4 justify-between lg:(ml-4)`}
                  to="/pdf/ppl-enrollment-form-espanol-2023.pdf"
                >
                  Español
                </DownloadButton>
              </Div>
            </CallOut>
            <CallOut
              blue
              bgColor="pastelGray"
              barColor="cyan"
              customCss={tw`py-4 md:(py-8)`}
              ctaText={
                <>
                  Sample letter of medical
                  <br /> necessity
                </>
              }
            >
              <DownloadButton
                customCss={tw`flex gap-4 justify-between`}
                to="/pdf/sample-letter.pdf"
              >
                Download
              </DownloadButton>
            </CallOut>
          </Div>
        </ContentSection>
      </section>
      <section>
        <ContentSection flavor="dark" customCss={tw`pt-3 md:(pt-12 pb-20)`}>
          <H2 cyan tw="mb-4 md:(mb-12)">
            The <NERLYNX /> patient co-pay card is available
          </H2>
          <CallOut
            blue
            bgColor="white"
            barColor="cyan"
            customCss={tw`mb-4 py-2 md:(py-0)`}
            ctaText={
              <p tw="text-left font-size[18px] line-height[1.25em] md:(font-size[24px])">
                Puma Biotechnology offers co-pay assistance to help qualified
                commercially insured patients with the cost of their <NERLYNX />{' '}
                prescription
              </p>
            }
          >
            <BlueButton size="cta" to={links.copay} customCss={tw`mb-6`}>
              <NERLYNX /> co-pay card{' '}
            </BlueButton>
          </CallOut>
          <UL yellow tw="mb-12 md:(mb-16)">
            <LI customCss={tw`font-thin`}>
              Eligible commercially insured patients treated with <NERLYNX />{' '}
              may pay as little as $10 per prescription.* Patients can be
              enrolled through their specialty pharmacy. To verify your
              patients’ eligibility or to enroll them in the co-pay card
              program,{' '}
              <LinkPlus to={links.copay} tw="no-underline">
                visit this website
              </LinkPlus>{' '}
              or call Puma Patient Lynx at{' '}
              <PhoneLink phoneNumber="1-855-816-5421" />
            </LI>
          </UL>
          <H3 white tw="mb-6 normal-case font-size[24px] line-height[1.5em]">
            National Drug Code (NDC) for <NERLYNX /> 40 mg film-coated tablets
          </H3>
          <div
            tw="relative w-full mb-4 bg-brand-pastel-dark-blue p-4 md:(px-12 py-2)
          after:(absolute bg-brand-cyan content[''] inset-x-0 bottom-0 h-1)"
          >
            <table tw="w-full flex flex-col font-size[20px]">
              <tbody>
                <tr tw="border-brand-white border-width[0 0 1px 0] py-3 flex gap-4">
                  <th tw="text-left w-2/5 md:(pl-10)">Package size</th>
                  <th tw="text-left">NDC</th>
                </tr>
                <tr tw="border-brand-white border-width[0 0 .5px 0] py-3 flex  gap-4 font-thin">
                  <td tw="w-2/5 md:(pl-10)">133 tablets</td>
                  <td>NDC 70437-240-33</td>
                </tr>
                <tr tw="py-3 flex gap-4 font-thin">
                  <td tw="w-2/5 md:(pl-10)">180 tablets</td>
                  <td>NDC 70437-240-18</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p tw="text-brand-white mb-6">
            Use the 133-tablet NDC for the first month of treatment when
            initiating dose escalation for <NERLYNX />.
          </p>
          <Limitations>
            Limitations apply. Patient must have commercial insurance. Offer is
            not valid under Medicare, Medicaid, or any other federal or state
            program. Puma Biotechnology reserves the right to rescind, revoke,
            or amend this program without notice. For full terms and conditions,
            call <PhoneLink phoneNumber="1-855-816-5421" />.
          </Limitations>
        </ContentSection>
        <HR yellow></HR>
      </section>
      <section id="reimbursement-and-ongoing-treatment">
        <ContentSection flavor="circle" customCss={tw`py-4 md:(py-8)`}>
          <PatientPumalynx tw="h-14 mb-4 mt-12 md:(h-16)" />
          <H2 cyan tw="mb-4 md:(mb-8)">
            Support for patients prescribed <NERLYNX />
          </H2>
          <ul
            tw="grid grid-cols-1 gap-y-4 lg:(grid-cols-2 gap-x-4)"
            css={breakoutRightDesktop}
          >
            <div tw="flex flex-col gap-4">
              <H3 tw="mb-2 inline-block ml-16 font-size[24px] text-brand-blue font-medium md:(mb-6)">
                Access & coverage
              </H3>
              <li>
                <AccessCard
                  title="Benefits Verification & Prior Authorization Assistance"
                  description="This program provides comprehensive support, including benefits investigation to verify insurance coverage and assistance with prior authorization."
                >
                  <MagnifyingGlass alt="Assistance icon" />
                </AccessCard>
              </li>
              <li>
                <AccessCard
                  title="caps{Nerlynx} Quick Start"
                  description="Provides a free 3-week supply of NERLYNX to eligible patients experiencing delays in gaining access to therapy."
                >
                  <MouseClick alt="Quick start icon" />
                </AccessCard>
              </li>
              <li>
                <AccessCard
                  title="Co-Pay Assistance"
                  description={
                    <P>
                      Eligible commercially insured patients treated with
                      NERLYNX may pay as little as $10 per prescription.
                      Patients can be enrolled through their specialty pharmacy
                      or through the Access and Support page on{' '}
                      <span tw="whitespace-pre">
                        <LinkPlus
                          tw="no-underline"
                          to="https://www.nerlynx.com"
                        >
                          nerlynx.com
                        </LinkPlus>
                        .
                      </span>
                    </P>
                  }
                >
                  <Copay alt="Co-pay icon" />
                </AccessCard>
              </li>
              <li>
                <AccessCard
                  title="Financial Support Information"
                  description="For patients with financial needs, we will work to find appropriate sources for support."
                >
                  <Money alt="Financial support icon" />
                </AccessCard>
              </li>
            </div>
            <div tw="flex flex-col gap-4">
              <H3 tw="mb-2 ml-16 text-lg font-size[24px] text-brand-blue font-medium md:(mb-6)">
                Ongoing Treatment
              </H3>
              <li>
                <AccessCard
                  title="caps{Nerlynx} Mentor Program"
                  description="Patient mentors provide confidential support for patients who are considering or currently taking NERLYNX.*"
                >
                  <Mentor alt="mentor icon" />
                </AccessCard>
              </li>
              <li>
                <AccessCard
                  title="Product Support"
                  description="Our specialty pharmacy network will provide patients with product education and side-effect counseling to help them better understand and manage their NERLYNX therapy."
                >
                  <File alt="support icon" />
                </AccessCard>
              </li>
              <li>
                <AccessCard
                  title="Nurse Call Center"
                  description={
                    <P>
                      Our staff of registered nurses by training are available
                      to speak with patients and healthcare providers to answer
                      questions about NERLYNX.<sup>†</sup>{' '}
                      <strong tw="font-bold">
                        The call center is open Monday to Friday, 9 AM to 6 PM
                        ET, for your convenience. Call{' '}
                        <PhoneLink phoneNumber="1-855-816-5421" /> (when
                        prompted, press 2).{' '}
                      </strong>
                      <i>Bilingual nurses are available</i>.
                    </P>
                  }
                >
                  <Nurse alt="nurse icon" />
                </AccessCard>
              </li>
            </div>
          </ul>
          <Footnotes footnotes={['mentors', 'informational service']} />
        </ContentSection>
      </section>
      <section id="specialty-pharmacies-distributors">
        <ContentSection flavor="lightGray" customCss={tw`py-2`}>
          <H2 cyan tw="mb-0">
            Specialty distributors
          </H2>
          <P gray tw="mb-4 mt-0 font-size[12px] md:(text-sm)">
            Current as of August 2022
          </P>
          <ul
            tw="grid grid-cols-1 gap-y-8 sm:(grid-cols-2 gap-x-4) md:(gap-y-12)"
            css={breakoutRightDesktop}
          >
            <li tw="sm:(grid-column[1] grid-row[1])">
              <Contact
                name="Cardinal Health™ Specialty Solutions"
                phone="(866) 677-4844"
              >
                <LinkPlus
                  customCss={linkStyle}
                  to="https://www.cardinalhealth.com"
                >
                  www.cardinalhealth.com
                </LinkPlus>
              </Contact>
            </li>
            <li tw="sm:(grid-column[1] grid-row[2])">
              <Contact
                name="McKesson Plasma and Biologics"
                phone="(877) 625-2566"
              >
                <LinkPlus
                  customCss={linkStyle}
                  to="https://www.mckesson.com/plasmabiologics"
                >
                  www.mckesson.com/plasmabiologics
                </LinkPlus>
              </Contact>
            </li>
            <li tw="sm:(grid-column[2] grid-row[1])">
              <Contact name="McKesson Specialty Health" phone="(800) 482-6700">
                <LinkPlus
                  customCss={linkStyle}
                  to="https://www.mckessonspecialtyhealth.com"
                >
                  www.mckessonspecialtyhealth.com
                </LinkPlus>
              </Contact>
            </li>
            <li tw="sm:(grid-column[2] grid-row[2])">
              <Contact name="ASD Healthcare" phone="(800) 746-6273">
                <LinkPlus
                  customCss={linkStyle}
                  to="https://www.asdhealthcare.com"
                >
                  www.asdhealthcare.com
                </LinkPlus>
              </Contact>
            </li>
          </ul>
        </ContentSection>
      </section>
      <section>
        <ContentSection>
          <H2 cyan tw="mb-0">
            Specialty pharmacy network
          </H2>
          <P gray tw="mb-4 mt-0 font-size[12px] md:(text-sm)">
            Current as of August 2022
          </P>
          <ul tw="grid grid-cols-1 gap-y-12 sm:(grid-cols-2 gap-x-4)">
            <li tw="sm:(grid-column[1] grid-row[1])">
              <Contact
                name="AcariaHealth™"
                phone={
                  <span>
                    T: <PhoneLink phoneNumber="(800) 511-5144" /> F:{' '}
                    <PhoneLink
                      phoneNumber="(877)
                    541-1503"
                    />
                  </span>
                }
                hours={
                  <>
                    Hours of operation: M-F, 8 <AM />
                    -10 <PM /> (ET)
                  </>
                }
              >
                <LinkPlus customCss={linkStyle} to="http://acariahealth.com">
                  www.acariahealth.com
                </LinkPlus>
              </Contact>
            </li>
            <li tw="sm:(grid-column[1] grid-row[2])">
              <Contact
                name="Biologics, Inc."
                phone={
                  <span>
                    T: <PhoneLink phoneNumber="(800) 850-4306" /> F:{' '}
                    <PhoneLink
                      phoneNumber="(800)
                    823-4506"
                    />
                  </span>
                }
                hours={
                  <>
                    Hours of operation: M-F, 8 <AM />
                    -8 <PM /> (ET)
                  </>
                }
              >
                <LinkPlus
                  customCss={linkStyle}
                  to="https://www.biologicsinc.com"
                >
                  www.biologicsinc.com
                </LinkPlus>
              </Contact>
            </li>
            <li tw="sm:(grid-column[1] grid-row[3])">
              <Contact
                name={
                  <>
                    Optum
                    <sup>®</sup> Specialty Pharmacy
                  </>
                }
                phone={
                  <span>
                    T: <PhoneLink phoneNumber="(877) 445-6874" /> F:{' '}
                    <PhoneLink
                      phoneNumber="(877)
                    342-4596"
                    />
                  </span>
                }
                hours={
                  <>
                    Hours of operation: M-F, 8 <AM />
                    -10 <PM /> (ET); Sat., 9 <AM />
                    -8 <PM /> (ET)
                  </>
                }
              >
                <LinkPlus
                  customCss={linkStyle}
                  to="https://specialty.optumrx.com"
                >
                  specialty.optumrx.com
                </LinkPlus>
              </Contact>
            </li>
            <li tw="sm:(grid-column[2] grid-row[1])">
              <Contact
                name={
                  <>
                    Accredo<sup>®</sup> Health Group, Inc.
                  </>
                }
                phone={
                  <span>
                    T: <PhoneLink phoneNumber="(877) 732-3431" /> F:{' '}
                    <PhoneLink
                      phoneNumber="(866)
                    352-3973"
                    />
                  </span>
                }
                hours={
                  <>
                    Hours of operation: M-F, 8 <AM />
                    -8 <PM /> (ET)
                  </>
                }
              >
                <LinkPlus customCss={linkStyle} to="https://www.accredo.com">
                  www.accredo.com
                </LinkPlus>
              </Contact>
            </li>
            <li tw="sm:(grid-column[2] grid-row[2])">
              <Contact
                name="CVS Specialty Pharmacy"
                phone={
                  <span>
                    T: <PhoneLink phoneNumber="(844) 239-4761" /> F:{' '}
                    <PhoneLink
                      phoneNumber="(855)
                    296-0210"
                    />
                  </span>
                }
                hours={
                  <>
                    Hours of operation: M-F, 8:30 <AM />
                    -8:30 <PM /> (ET)
                  </>
                }
              >
                <LinkPlus
                  customCss={linkStyle}
                  to="https://www.cvsspecialty.com"
                >
                  www.cvsspecialty.com
                </LinkPlus>
              </Contact>
            </li>
            <li tw="sm:(grid-column[2] grid-row[3])">
              <Contact
                name="Onco360 Oncology Pharmacy"
                phone={
                  <span>
                    T: <PhoneLink phoneNumber="(877) 662-6633" /> F:{' '}
                    <PhoneLink
                      phoneNumber="(877)
                    662-6355"
                    />
                  </span>
                }
                hours={
                  <>
                    Hours of operation: M-F, 8 <AM />
                    -7 <PM /> (ET); Sat., 9 <AM />
                    -2 <PM /> (ET)
                  </>
                }
              >
                <LinkPlus customCss={linkStyle} to="https://www.onco360.com">
                  www.onco360.com
                </LinkPlus>
              </Contact>
            </li>
          </ul>
        </ContentSection>
      </section>
      <section id="copay-assistance">
        <VoucherFooter />
      </section>
    </Layout>
  );
};

const linkStyle = tw`no-underline`;
const Limitations = tw.p`ml-2 pl-1 leading-4 font-size[.75rem] relative text-brand-pastel-gray before:(absolute -left-2 mr-1 top-0.5 content["*"])`;
const SmallCaps = tw.span`font-variant[small-caps]`;
const AM = () => <SmallCaps>am</SmallCaps>;
const PM = () => <SmallCaps>pm</SmallCaps>;
export default Page;
